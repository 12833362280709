import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faGlobe } from '@fortawesome/free-solid-svg-icons'
import {Outlet,  Link } from 'react-router-dom';
import './Header.css';

export default function Header() {
  return (
    <>
    <nav>
    <input type="checkbox" id="check" />
        <label htmlFor="check" className="checkbtn">
        < FontAwesomeIcon icon={faBars}  />
        <span>Menu</span>
        <FontAwesomeIcon icon={faGlobe}  />
        <span className="bar" > English</span>
        
        </label>
        <label className="logo"><Link to="/">MoveInRental</Link></label>
        <ul>
          
          
          <li><Link to="/ManageRental">Manage Rental</Link></li>
          <li><Link to='/SingIn'>Sign In</Link></li>
          <li><Link to='/SignUp'>Sign Up</Link></li >
          <li><Link to='/AddProperty'>Added a Property</Link></li>
        </ul>

    </nav>
    
    </>
  );
}
