import React, { useState, useEffect } from 'react';
import 'leaflet/dist/leaflet.css';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import Slider from 'react-slick';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft, faAngleRight, faArrowDown, faBell, faAngleDown } from '@fortawesome/free-solid-svg-icons';
import apar from './Images/apar.jpg';
import apartment from './Images/banner.jpg';
import house from './Images/House.jpg';

const customIcon = new L.Icon({
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  iconSize: [24, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
});

const MapMarker = ({ item }) => (
  <Marker position={[item.longitude, item.latitude]} icon={customIcon}>
    <Popup>
      <div>
        <div>
          <img
            src={item.imagePath}
            alt={item.title}
            style={{ width: '250px', height: '150px' }}
          />
        </div>
        <p>{item.title}</p>
        <p>{item.address}</p>
        <p className="mi4">
          {item.type === 'f' ? 'Studio' : 'Commercial'} - {item.bed_Size}{' '}
          {item.type === 'f' ? 'Beds' : 'Sq ft'}
        </p>
        <p className="mi4">${item.from_Price} - ${item.to_Price}</p>
        <hr />
        <div className="row">
          <p className="mi4">{item.mobile}</p>
          <p className="emailBtn" style={{ marginLeft: '15px' }}>
          vikas.dadlani@gmail.com
          </p>
        </div>
      </div>
    </Popup>
  </Marker>
);



function Poot(props) {
  const [data, setData] = useState([]);
  const [isPriceAscending, setIsPriceAscending] = useState(true);
  const [selectedPrice, setSelectedPrice] = useState('');
  const [selectedBed, setSelectedBed] = useState('');
  const [selectedCity, setSelectedCity] = useState('');

  const handleSortByPrice = () => {
    const sortedData = [...data].sort((a, b) => {
      if (isPriceAscending) {
        return a.from_Price - b.from_Price;
      } else {
        return b.from_Price - a.from_Price;
      }
    });
    setData(sortedData);
    setIsPriceAscending(!isPriceAscending);
  };

  useEffect(() => {
    fetch('https://mak6xa52l47jf7t663ywbdcxli0qvuzk.lambda-url.us-east-2.on.aws/RentApi')
        .then(response => response.json())
        .then(apiData => {
          setData(apiData);
          console.log(apiData);
        })
        .catch(error => console.error('Error fetching data:', error));
  }, []);

  const NextArrow = ({ onClick }) => (
      <div className="arrow next" onClick={onClick}>
        <FontAwesomeIcon className='le' icon={faAngleRight} />
      </div>
  );

  const PrevArrow = ({ onClick }) => (
      <div className="arrow prev" onClick={onClick}>
        <FontAwesomeIcon className='li' icon={faAngleLeft} />
      </div>
  );
  const handlePriceChange = (event) => {
    setSelectedPrice(event.target.value);
    // You can perform actions based on the selected price here
  };

  const handleBedChange = (event) => {
    setSelectedBed(event.target.value);
    // You can perform actions based on the selected bed count here
  };

  const handleCityChange = (event) => {
    setSelectedCity(event.target.value);
    // You can perform actions based on the selected city here
  };

  // Filter data based on the selected price
  const filteredByPrice = selectedPrice
      ? data.filter(item => parseInt(item.from_Price) === parseInt(selectedPrice))
      : data;

  // Filter data based on the selected bed count
  const filteredByBed = selectedBed
      ? filteredByPrice.filter(item => parseInt(item.bed_Size) === parseInt(selectedBed))
      : filteredByPrice;

  // Filter data based on the selected city
  const filteredByCity = selectedCity
      ? filteredByBed.filter(item => item.city === selectedCity)
      : filteredByBed;


  return (
      <div>
        <div className="hotel">
          <div className="p1">
            <input type='text' className='input1 ml-5 margin-bottom-5' name='name' value={props.name} />
            <select className='set mr-2 ml-2 p-1 mb-4' value={selectedPrice} onChange={handlePriceChange}>
              <label className="price1" value={selectedPrice} onChange={handlePriceChange}>Price <FontAwesomeIcon className="icon" icon={faAngleDown} /></label>

              <option value="">Price</option>
              <option value="1000">1000</option>
              <option value="2000">2000</option>
              <option value="3000">3000</option>
              <option value="4000">4000</option>
              <option value="5000">5000</option>
            </select>

            <select className="bedDropdown className='set mr-2 ml-2 p-1" value={selectedBed} onChange={handleBedChange}>
              <label className="bedDropdown price1" value={selectedBed} onChange={handleBedChange}>Bed <FontAwesomeIcon className="icon" icon={faAngleDown} /></label>

              <option value=""> beds</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              {/* Add more options as needed */}
            </select>

            <select className="bedDropdown className='set mr-2 ml-2 p-1" value={selectedCity} onChange={handleCityChange}>
              <label className="bedDropdown price1" value={selectedCity} onChange={handleCityChange}>City <FontAwesomeIcon className="icon" icon={faAngleDown} /></label>

              <option value=""> City</option>
              {data.map(item => (
                <option key={item.id} value={item.city}>{item.city}</option>
              ))}
            </select>

            <label className="price1">Type<FontAwesomeIcon className="icon" icon={faAngleDown} /> </label>
            <label className="pri2">Life Style <FontAwesomeIcon className="icon" icon={faAngleDown} /></label>
            <label className="pri3">Move-In Date <FontAwesomeIcon className="icon" icon={faAngleDown} /></label>
            <label className="sort">Sort <FontAwesomeIcon className="iconn" icon={faArrowDown} /></label>
            <label className="sortt">Save Search <FontAwesomeIcon className="iconn" icon={faBell} /></label>
          </div>
        </div>

        <div>
          <div className="row fix">
            <div className="col-sm-8">
              <MapContainer
                  center={[39.94887169551377, -83.04449097489344]}
                  zoom={11}
                  style={{ height: '100vh', width: '100%' }}
              >
                <TileLayer id="mapbox/streets-v11" url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"/>
                {data.map((item) => (
                    <MapMarker key={item.id} item={item} />
                ))}
              </MapContainer>
            </div>
            <div  className="col-sm-4 mb-2 smFlow">
              <button onClick={handleSortByPrice} className=' mb-2 p-2'>
                5 star Rent {isPriceAscending ? 'High' : ''}
              </button>
              {filteredByCity.length > 0 ? (
                  <ul>
                    {filteredByCity.map((item, index) => (
                        <div className='note'>
                          <div key={item.id} >
                            <h1 className="mi2">{item.title}</h1>
                            <h3 className="mi3">{item.address}</h3>
                            <Slider
                                dots={false}
                                infinite={true}
                                speed={500}
                                slidesToShow={1}
                                slidesToScroll={1}
                                nextArrow={<NextArrow />}
                                prevArrow={<PrevArrow />}
                                className='sliderr'
                            >
                              <div>
                                <img className='imagesClass img-fluid' src={`${item.imagePath}`} alt="First slide" />
                              </div>
                              <div>
                                <img className='imagesClass img-fluid' src={`${item.imagePath2 ?? item.imagePath}`} alt="First slide" />
                              </div>
                              <div >
                                <img className='imagesClass img-fluid' src={`${item.imagePath3 ?? item.imagePath}`} alt="First slide" />
                              </div>
                            </Slider>
                            <div className='toMarginDivContent ml-3'>
                              <h3 className="mi4">${item.from_Price} - ${item.to_Price}</h3>
                              <h3 className="mi5">
                                {item.type === "f" ? "Studio" : "Commercial"} - {item.bed_Size} {item.type === "f" ? "Beds" : "Sq ft"}
                              </h3>
                              <h3 className="mi6">
                                {item.description && item.description.slice(0, 50)}
                              </h3>
                              <h3 className="mi8">{item.mobile}</h3>
                              <h3 className="mi7 mr-2">vikas.dadlani@gmail.com</h3>
                              <hr />
                            </div>
                          </div>
                        </div>
                    ))}
                  </ul>
              ) : (
                  <p>
                    <Slider
                        dots={false}
                        infinite={true}
                        speed={500}
                        slidesToShow={1}
                        slidesToScroll={1}
                        nextArrow={<NextArrow />}
                        prevArrow={<PrevArrow />}
                        className='blu2'
                    >
                      <div>
                        <img className='imagesClass img-fluid' src={apar} alt="First slide" />
                      </div>
                      <div>
                        <img className='imagesClass img-fluid' src={apartment} alt="First slide" />
                      </div>
                      <div >
                        <img className='imagesClass img-fluid' src={house} alt="First slide" />
                      </div>
                    </Slider>
                    <div className='toMarginDivContent blu ml-3'>
                      <h3 className="mi4">$1000 - $1500</h3>
                      <h3 className="mi5">
                      </h3>
                      <h3 className="mi6">
                      </h3>
                      <h3 className="mi4">8184 686</h3>
                      <h3 className="mi7 mr-2">vikas.dadlani@gmail.com</h3>
                    </div>
                    <h3 className='blu3'>Not Avaiable yet</h3>
                  </p>
              )}
            </div>
          </div>
        </div>
      </div>
  );
}

export default Poot;
