import React from 'react';
import Header from './Header';
import Home from './Home';
import { Routes, Route } from 'react-router-dom';
import Menu from './Menu';
import Manage from './Manage';
import Last from './Last';

export default function App() {
  return (
    <>
    <Header />
    <Routes>
        
        <Route path='/' element={<Manage />}> </Route>
       
      
      </Routes>
    
    </>

  );
}
