import React from 'react';
import apar from "./Images/banner.jpg"
import './Home.css';





export default function Home(props) {
  return (
    <>
    <div className='bg'>
      <img src={apar} alt='apar' className="img" />
      <div className='center'>
        <label className='new'>Discover Your New Home</label>
        <label className='new1' >Helping 100 million renters find their perfect fit.</label>
        <form onSubmit={props.submit} >
        <input type="text" className="input" name="name"  />
        <button type="Submit" id="submit" className="btn btn-primary m-1" >Search</button>
        </form>
      </div>
     </div>
  
    </> 
  );
}
