import React, { Component } from 'react';
import Home from './Home';
import Menu from './Menu';
import Header from './Header';


export class Manage extends Component {
    constructor(props) {
      super(props)
    
      this.state = {
         isregister : false,
         name : null,
      }
    }

    submitHandler = (event) => {
        event.preventDefault();
        console.log(event.target.name.value);
        const name = event.target.name.value;
        
    
        this.setState({name, isregister: true})
      }
    
  render() {
    return (
      <div>
        
        {this.state.isregister ? (<Menu name = {this.state.name}  />) : <Home submit = {this.submitHandler}></Home>}
      </div>
    );
  }
}

export default Manage;
