import React, { useState, useEffect } from 'react';
import "./Menu.css";
import GoogleMapReact from 'google-map-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faGlobe } from '@fortawesome/free-solid-svg-icons';
import { faArrowDown, faBell } from '@fortawesome/free-solid-svg-icons';
import Poot from './Poot';

export default function Menu(props) {
  const [selectedMarker, setSelectedMarker] = useState(null); // State to track the selected marker

 
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const options = ['Beds', '1 Beds', '2 Beds', '3 Beds'];
  const [isPriceAscending, setIsPriceAscending] = useState(true);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
    // Do something with the selected option, like calling a function or updating state
  };

  return (
    <>
     <div className="hotel">

      <Poot />
      </div>
    </>
  );
}
